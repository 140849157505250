/* REFERENCES - 2
--------------------------------------------------------------------------------------------*/

[data-s-type="references"] {
  // items slider
  $items-slider-fouc-height: 200px; // what needs to be the flash-of-unwanted-content height before slider has laoded.

  // items
  $items-amount: 0; // 0 counts for undefined, if assigned the code will be more optimised
  $items-content-over-image: true;
  $items-have-padding: false;
  $items-have-border-dividers: false;
  $items-have-border-below: false;
  $items-hide-border-below-on-last-row: true;
  $items-border-radius: 0;
  $items-first-item-full-width: false;
  $items-first-item-full-width-height-multiplier: 1.5;
  $items-on-small-two-items-a-row: false;
  $item-background: none;
  $item-background-hover: none;

  // columns
  $items-columns-enabled: false;
  $items-columns-breakpoint: 800px;
  $items-columns-height: 400px;
  $items-columns-hide-last-one-below-breakpoint: false;
  $items-columns-icon-image-combination: true;

  // item
  $item-margin-bottom: 1px; // 1.4em
  $item-border: $default-border; // defines the appearance, not the display
  $item-shadow-enabled: false;

  // image
  $item-image-container-enabled: true;
  $item-image-enabled: true;
  $item-image-height: 320px;
  $item-image-initial-fade: 1;
  $item-image-hover-fade: 0.6;
  $item-image-gradient-before: true; // when item-content-over-image
  $item-image-gradient-color: darken($brand-secondary, 15%);
  $item-image-gradient-before-position: "bottom"; // top or bottom
  $item-image-background-color: $brand-secondary;
  $item-image-background-color-hover: $brand-secondary-darker;
  $item-image-border-radius: 0;

  // content
  $item-content-padding: $default-padding;
  $item-content-background: none;
  $item-content-background-hover: none;
  $item-content-align: "cc"; // lt, lc, lb, ct, cc, cb, rt, rc, rb ||  when item-content-over-image
  $item-content-copy-padding: $default-padding 0;
  $item-content-copy-align: "left"; // left or center

  $item-content-icon-enabled: true;
  $item-content-icon-centered: true;
  $item-content-icon-color: rgba($default-icon-color-light, 1);
  $item-content-icon-hover-color: rgba($default-icon-color-dark, 1);
  $item-content-icon-size: 80px;
  $item-content-icon-padding: 10px;
  $item-content-icon-margin: 0 0 20px 0;
  $item-content-icon-background: rgba($default-background-light, 0.5);
  $item-content-icon-background-hover: rgba($default-background-light, 1);
  $item-content-icon-round: true;

  $item-content-title-enabled: true;
  $item-content-title-color: $default-text-color-light;
  $item-content-title-hover-color: rgba($default-text-color-light, 0.8);

  $item-content-copy-above-enabled: true;
  $item-content-copy-above-color: rgba($default-text-color-light, 0.6);
  $item-content-copy-above-hover-color: rgba($default-text-color-light, 0.4);

  $item-content-copy-below-enabled: false;
  $item-content-copy-below-color: rgba($default-text-color-light, 0.6);
  $item-content-copy-below-hover-color: rgba($default-text-color-light, 0.4);

  // icon
  $item-show-icon: true;
  $item-icon-color: rgba($default-icon-color-light, 0.4);
  $item-icon-hover-color: rgba($default-icon-color-light, 1);
  $item-icon-size: 1.6em;
  $item-icon-position: "rb"; // lt, lb, rt, rb
  $item-icon-inset-left-right: $default-padding;
  $item-icon-inset-top-bottom: $default-padding;

  // list style on small | image left and content right | all background will be reset | content icon is set absolute. Not possible in conjunction with a slider
  $item-list-style-on-small: false;
  $item-list-style-image-width: 100px;
  $item-list-style-content-icon-size: 60px;
  $item-list-style-content-icon-padding: 14px;
  $item-list-style-content-icon-background: $default-background-light;
  $item-list-style-content-icon-color: $default-icon-color-dark;
  $item-list-style-content-title-color: rgba($default-text-color, 1);
  $item-list-style-content-copy-above-color: rgba($default-text-color, 1);
  $item-list-style-content-copy-below-color: rgba($default-text-color, 1);

  @import "../ac_s__items-globals/__item_globals";

  /*--------------------------------------------------------------------------------------------*/

  .ac_item_image-container {
    border: solid 1px $brand-white;
  }
  .ac_item_image {
    border-radius: 0;
  }

  .ac_item_content_title {
    @include font-headings-bold;
    font-size: 150%;
    line-height: 1;
    transition: opacity 0.3s ease;
  }
} // end scope
