/* FIXES
--------------------------------------------------------------------------------------------*/

.v_wider-left-column {

    @include breakpoint(medium-portrait) {
        label {
            width: 60% !important;
        }
        select {
            width: 39% !important;
        }
    }
}


input[type="checkbox"] {
    margin-bottom: 5px !important;
}



//
//     FORM SERVICE ABONNEMENT
//
#gform_5 {
    #input_5_9 {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 100%;
            margin: 0 0 10px 0 !important;
        }
    }
}
 .ac_menu-2_bar_top {
     ul {
         display: flex;
     }
 }
 .ac_hero_slide_content_text {
     display: none;
 }

[data-s-id="home-news"] .ac_item_image-container {
     display: none;
 }